import { TagTheme } from '@/components/Tag/theme';
import { tw } from '@/utils/tw';
import BaseTrendingMenuTheme from 'base/components/TrendingMenu/theme';

const Tag = tw.theme({
  extend: TagTheme,
  defaultVariants: {
    size: 'small',
  },
});

const TrendingMenu = tw.theme({
  extend: BaseTrendingMenuTheme,
  slots: {
    base: ['mx-auto', 'my-5', 'w-full', 'gap-3'],
    headline: ['text-preamble-sm'],
  },
});

export default Object.assign(TrendingMenu, { Tag });
